import Hero from "@components/shared/hero";
import SEO from "@components/shared/seo";
import React, { useEffect } from "react";
import LeadershipMembers from "../components/pages-components/leadership/members";
import LayOut from "@components/layout";
import { graphql, PageProps } from "gatsby";
import { Query, SanityLeadershipPageConnection } from "@graphql-types";
import { useRegionChanged } from "@util/hooks";

interface Data extends Query {
  altRegions: SanityLeadershipPageConnection;
}

interface Props extends PageProps {
  data: Data;
  pageContext: any;
}

const HelpCentre = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useRegionChanged("leadership");

  const sanityLeadershipPage = props.data.allSanityLeadershipPage.nodes[0];
  const alternateRegions = props.data.altRegions.nodes.map(alt => alt._rawRegion);

  return (
    <>
      <SEO
        seoData={sanityLeadershipPage.seo}
        alternateRegions={alternateRegions}
        slug={props.pageContext.slug}
      />

      <LayOut>
        <Hero singleHeroContent={sanityLeadershipPage.hero} />
        <LeadershipMembers sanityLeadershipPage={sanityLeadershipPage} />
      </LayOut>
    </>
  );
};

export default HelpCentre;

export const query = graphql`
  query leadershipQuery($iban: String) {
    allSanityLeadershipPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        members {
          title
          description
          members {
            profileImage {
              ...sanityImageWithMeta
            }
            fullName
            jobTitle
          }
        }
        advisoryMembers {
          title
          description
          members {
            profileImage {
              ...sanityImageWithMeta
            }
            fullName
            jobTitle
          }
        }
        clinicalMembers {
          title
          description
          members {
            profileImage {
              ...sanityImageWithMeta
            }
            fullName
            jobTitle
          }
        }
      }
    }
    altRegions: allSanityLeadershipPage {
      nodes {
        _rawRegion(resolveReferences:{maxDepth:10})
      }
    }
  }
`;
